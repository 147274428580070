import {
    DockableContainer,
    DockPosition,
} from '@local/web-design-system/dist/components/DockableContainer';
import { PropertiesHeader } from '@local/web-design-system/dist/components/PropertiesHeader';
import { MeasurementDialog } from '@local/webviz/dist/components/Measurement';
import { MEASUREMENT_TITLE } from '@local/webviz/dist/components/Measurement/MeasurementDialog.constants';
import {
    PROPERTIES_TITLE,
    PropertiesDialog,
} from '@local/webviz/dist/components/Properties/PropertiesDialog';
import { SelectionContents, SelectionPanel } from '@local/webviz/dist/components/Selection';
import { Settings, SETTINGS_TITLE } from '@local/webviz/dist/components/Settings';
import { SettingsVisualizationOverlays } from '@local/webviz/dist/components/Settings/Settings.types';
import {
    SLICE,
    DockableSliceDialog as SliceDialog,
} from '@local/webviz/dist/components/Slice/SliceDialog';
import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import { useSelection } from '@local/webviz/dist/context/hooks/useSelection';

import { useSelectedObjectsAttributes } from 'src/hooks/useSelectedObjectsAttributes';
import { SchemaIcon } from 'src/pages/workspacePage/workspaceContent/SchemaIcon';
import { getAttributeNameColormapsMap } from 'src/store/colormap/selectors';
import { store, useAppDispatch, useAppSelector } from 'src/store/store';
import {
    loadedObjectById,
    orientationVisible,
    scalebarVisible,
    selectionListScenePanel,
    getTreeItemById,
} from 'src/store/visualization/selectors';
import { updateOverlays } from 'src/store/visualization/visualizationSlice';

import {
    getObjectIdFromViewId,
    getViewIdFromObjectId,
} from '../context/snapshots/generateSnapshot';
import { DockableDialogProps, DockedWindows } from './DockableDialogs.types';

const DOCK_THRESHOLD = 25;
export function DockableSliceDialog({ onClose, defaultPosition }: Readonly<DockableDialogProps>) {
    return (
        <SliceDialog
            header={{ title: SLICE, onClose }}
            type={DockedWindows.Slice}
            dockPosition={DockPosition.UPPER}
            defaultPosition={defaultPosition}
            dockThreshold={DOCK_THRESHOLD}
        />
    );
}

export function DockableMeasureDialog({ onClose, defaultPosition }: Readonly<DockableDialogProps>) {
    return (
        <DockableContainer
            header={{ title: MEASUREMENT_TITLE, onClose }}
            type={DockedWindows.Measure}
            dockPosition={DockPosition.LOWER}
            defaultPosition={defaultPosition}
            dockThreshold={DOCK_THRESHOLD}
        >
            <MeasurementDialog />
        </DockableContainer>
    );
}

export function DockableSettingsDialog({
    onClose,
    defaultPosition,
}: Readonly<DockableDialogProps>) {
    const isOrientationChecked = useAppSelector(orientationVisible);
    const isScalebarChecked = useAppSelector(scalebarVisible);
    const dispatch = useAppDispatch();
    const updateOverlaysDispatch = (overlays: Partial<SettingsVisualizationOverlays>) =>
        dispatch(updateOverlays(overlays));
    const { getEntityState } = useBaseXyz();
    const plotState = getEntityState('plot');
    let numberOfObjects = 0;
    if (plotState && 'views' in plotState) {
        numberOfObjects = plotState.views.length;
    }

    return (
        <DockableContainer
            type={DockedWindows.Settings}
            header={{ title: SETTINGS_TITLE, onClose }}
            dockPosition={DockPosition.LOWER}
            defaultPosition={defaultPosition}
            dockThreshold={DOCK_THRESHOLD}
        >
            <Settings
                isOrientationChecked={isOrientationChecked}
                isScalebarChecked={isScalebarChecked}
                numberOfObjects={numberOfObjects}
                updateOverlays={updateOverlaysDispatch}
            />
        </DockableContainer>
    );
}
export function DockablePropertiesDialog({
    onClose,
    defaultPosition,
}: Readonly<DockableDialogProps>) {
    const selectedObjectIds = useAppSelector(selectionListScenePanel);
    const { firstSelectedTreeItem, attributes } = useSelectedObjectsAttributes(selectedObjectIds);

    const selectedViewIds = Object.values(selectedObjectIds).map((objectId) => {
        const selectedTreeItem = getTreeItemById(objectId)(store.getState());
        return getViewIdFromObjectId(objectId, selectedTreeItem?.schema ?? '');
    });
    const multipleSelected = selectedViewIds.length > 1;
    const showHeaderAdornment = !multipleSelected && firstSelectedTreeItem?.name;
    const attributeNameColormaps = useAppSelector(
        getAttributeNameColormapsMap(selectedObjectIds[0]),
    );
    const attributeNames = Object.keys(attributeNameColormaps);
    const path = 'attributes';
    const filteredAttributeColormaps = attributeNames.reduce(reduceFilteredColormapsByPath, {});

    function reduceFilteredColormapsByPath(acc: {}, attributeName: string) {
        const attributeColormaps = attributeNameColormaps[attributeName];
        const filteredColormaps = attributeColormaps.filter((colormap) => colormap.path === path);
        return { ...acc, [attributeName]: filteredColormaps };
    }

    return (
        <DockableContainer
            header={{
                title: PROPERTIES_TITLE,
                headerAdornment: showHeaderAdornment && (
                    <PropertiesHeader
                        name={firstSelectedTreeItem.name}
                        Icon={SchemaIcon(firstSelectedTreeItem.schema)}
                    />
                ),
                onClose,
            }}
            type={DockedWindows.Properties}
            dockPosition={DockPosition.LOWER}
            defaultPosition={defaultPosition}
            dockThreshold={DOCK_THRESHOLD}
        >
            <PropertiesDialog
                selectedViewIds={selectedViewIds}
                attributes={attributes}
                showEmptyMessage={!firstSelectedTreeItem?.name}
                objectColormaps={filteredAttributeColormaps}
            />
        </DockableContainer>
    );
}

export function DockableSelectionPanel() {
    const { getEntityState } = useBaseXyz();
    const { selectionState } = useSelection();

    const viewId = selectionState?.viewId ?? '';
    const viewState = getEntityState(viewId);

    const objectId = getObjectIdFromViewId(viewId);
    const selectedObject = useAppSelector(loadedObjectById(objectId));
    if (!selectedObject) return null;
    const { name, schema } = selectedObject;

    if (!selectionState?.position || !viewState || !selectedObject) {
        return null;
    }

    const Icon = SchemaIcon(schema);

    const stylePosition = {
        bottom: 16,
        left: 8,
    };

    // TODO add selection contents when ready
    return (
        <DockableContainer type={DockedWindows.SelectionPanel} stylePosition={stylePosition}>
            <SelectionPanel title={name} Icon={Icon}>
                <SelectionContents selectionKeyValues={[]} />
            </SelectionPanel>
        </DockableContainer>
    );
}
